<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <section class="subHeader-content">
        <BlueHeader :itemsHeader="itemsHeader" />

        <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
        <v-slide-y-transition mode="out-in">
            <router-view></router-view>
        </v-slide-y-transition>


    </section>
</template>
  
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
    name: 'ServiceRequesMain',
    components: { BlueHeader },
    data: () => ({

        itemsHeader: [
            {
                name: 'Formulario Solicitud',
                link: { name: 'modules.validatorMeshMain.serviceRequest.formularioSolicitud' },
                rol: Role.MallaValidadora_SolicitudServicio_FormularioSolicitud,
            },
            {
                name: 'Cargar RIPS',
                link: { name: 'modules.validatorMeshMain.serviceRequest.cargarRips' },
                rol:  [Role.MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno, Role.MallaValidadora_SolicitudServicio_FormularioSolicitud_Externo_prestador ]   
            }

        ]
    }),
}
</script>
  
<style scoped>
.subHeader-content {
    width: 96%;
}
</style>